// Generated by Framer (d03ec02)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getFonts, getPropertyControls, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { Icon as Phosphor } from "https://framerusercontent.com/modules/tYScH7LTqUtz5KUaUAYP/CAjjxbTJBxHwH1MagCef/Phosphor.js";
const PhosphorFonts = getFonts(Phosphor);
const PhosphorControls = getPropertyControls(Phosphor);

const cycleOrder = ["U7N9gaM1W"];

const serializationHash = "framer-Pc2rM"

const variantClassNames = {U7N9gaM1W: "framer-v-1vln3w"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({background, color, height, id, name1, width, ...props}) => { return {...props, fpHSjujFN: name1 ?? props.fpHSjujFN ?? "Database", nWAtjDiVK: background ?? props.nWAtjDiVK ?? "var(--token-a77ab39c-a63e-47df-bbea-647b5ab99635, rgb(18, 23, 28)) /* {\"name\":\"Gray/9\"} */", y9KiRtf6c: color ?? props.y9KiRtf6c ?? "var(--token-e47fa55e-3968-4e03-b936-816fe655d59a, rgb(238, 240, 244)) /* {\"name\":\"Gray/95\"} */"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;name1?: string;color?: string;background?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, fpHSjujFN, y9KiRtf6c, nWAtjDiVK, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "U7N9gaM1W", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1vln3w", className, classNames)} data-framer-name={"Default"} layoutDependency={layoutDependency} layoutId={"U7N9gaM1W"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{backgroundColor: nWAtjDiVK, borderBottomLeftRadius: 96, borderBottomRightRadius: 96, borderTopLeftRadius: 96, borderTopRightRadius: 96, boxShadow: "0px 0px 20px 0px rgba(0, 0, 0, 0.25)", ...style}}><motion.div className={"framer-13ri6l-container"} layoutDependency={layoutDependency} layoutId={"UHdL1i3QK-container"}><Phosphor color={y9KiRtf6c} height={"100%"} iconSearch={"House"} iconSelection={fpHSjujFN} id={"UHdL1i3QK"} layoutId={"UHdL1i3QK"} mirrored={false} selectByList style={{height: "100%", width: "100%"}} weight={"regular"} width={"100%"}/></motion.div></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-Pc2rM[data-border=\"true\"]::after, .framer-Pc2rM [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-Pc2rM.framer-xwl62x, .framer-Pc2rM .framer-xwl62x { display: block; }", ".framer-Pc2rM.framer-1vln3w { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: hidden; padding: 24px 24px 24px 24px; position: relative; width: min-content; will-change: var(--framer-will-change-override, transform); }", ".framer-Pc2rM .framer-13ri6l-container { flex: none; height: 48px; position: relative; width: 48px; z-index: 1; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-Pc2rM.framer-1vln3w { gap: 0px; } .framer-Pc2rM.framer-1vln3w > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-Pc2rM.framer-1vln3w > :first-child { margin-left: 0px; } .framer-Pc2rM.framer-1vln3w > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 96
 * @framerIntrinsicWidth 96
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]}}}
 * @framerVariables {"fpHSjujFN":"name1","y9KiRtf6c":"color","nWAtjDiVK":"background"}
 * @framerImmutableVariables true
 */
const FramerQ0la2J8wn: React.ComponentType<Props> = withCSS(Component, css, "framer-Pc2rM") as typeof Component;
export default FramerQ0la2J8wn;

FramerQ0la2J8wn.displayName = "Icon in Circle";

FramerQ0la2J8wn.defaultProps = {height: 96, width: 96};

addPropertyControls(FramerQ0la2J8wn, {fpHSjujFN: PhosphorControls?.["iconSelection"] && {...PhosphorControls["iconSelection"], defaultValue: "Database", hidden: undefined, title: "Name"}, y9KiRtf6c: {defaultValue: "var(--token-e47fa55e-3968-4e03-b936-816fe655d59a, rgb(238, 240, 244)) /* {\"name\":\"Gray/95\"} */", title: "Color", type: ControlType.Color}, nWAtjDiVK: {defaultValue: "var(--token-a77ab39c-a63e-47df-bbea-647b5ab99635, rgb(18, 23, 28)) /* {\"name\":\"Gray/9\"} */", title: "Background", type: ControlType.Color}} as any)

addFonts(FramerQ0la2J8wn, [...PhosphorFonts])